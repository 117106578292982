import React from 'react'
import './insideheader.css'
import { Link } from 'gatsby'

const IconSocial = ({ itemHeading, itemContent, itemImage }) => (
    <p style={{display: "inline"}}>
    <Link target="_blank" to={itemContent}><span className="iconbox text-white bg-dark"><i className={itemImage}></i></span></Link>
    &nbsp;
  </p>


)

export default IconSocial
